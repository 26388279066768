import React, { Component } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Input } from 'antd';

import './Space.css';

export default class Space extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: this.props.space.class
        };
    }

    onChange(e) {
        this.setState({});
        this.props.space.class = e.target.value;
        this.props.saveSpace(this.props.space);
    }

    onDragStart(e) {
        e.dataTransfer.setData('name', e.target.id);
    }

    onDrop(e) {
        e.preventDefault();
        var id = e.dataTransfer.getData('name');
        if (id) {
            e.target.appendChild(document.getElementById(id));
        }
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onClick() {
        //document.querySelector('[aria-describedby="popover-basic"]').click();
    }

    render() {
        return (
            <li>
                <div className={"space " + this.props.space.class} onDrop={event => this.onDrop(event)} onDragOver={event => this.onDragOver(event)}>
                    <OverlayTrigger trigger="click" placement="right" overlay={
                        <Popover id="popover-basic">
                            <Popover.Title as="h3">Title</Popover.Title>
                            <Popover.Content>
                                <Input
                                    placeholder={"attributes"}
                                    size={"large"}
                                    onChange={value => this.onChange(value)}
                                    value={this.props.space.class}
                                />
                            </Popover.Content>
                        </Popover>}>
                        <div onClick={this.onClick()}>&nbsp;</div>
                    </OverlayTrigger>
                    {
                        this.props.space.row === 1 && this.props.space.col === 1
                        ?
                        <div id="piece" className="piece" draggable="true" onDragStart={event => this.onDragStart(event)}></div>
                        :
                        ''
                    }
                </div>
            </li>
        )
    }
}
