import React, { Component } from 'react';
import Space from './Space';

import './Board.css';

export default class Board extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 10
        }
    }

    getSpaceSize() {
        return this.state.zoom * 5 + 25;
    }

    getBoardSize() {
        let columns = 0;
        if (this.props.state.board.spaces.length) {
            columns = this.props.state.board.spaces[0].length;
        }
        let size = (columns * this.getSpaceSize() + 4) + 'px';
        return {width: size};
    }

    render() {
        return (
            <ul className={"board clear " + this.props.state.board.type} style={this.getBoardSize()}>
                {this.props.state.board.spaces.map(row =>
                    row.map(space =>
                        <Space
                            key={space.row + '_' + space.col}
                            space={space}
                            saveSpace={this.props.saveSpace}
                        />
                    )
                )}
            </ul>
        )
    }
}
