import React, { Component } from 'react';
import Board from './Board';

import './Game.css';

import {w3cwebsocket as W3CWebSocket} from 'websocket';

const client = new W3CWebSocket('wss://dragonslayer.luu.cloud');

export default class Game extends Component {
    state = {
        board: {
            on: false,
            type: 'square',
            spaces: []
        }
    };

    componentDidMount() {
        client.onopen = () => {
            console.log('WebSocket Client Connected');
            this.loadSpaces();
        };

        client.onmessage = (message) => {
            const data = JSON.parse(message.data);
            if (data.action === 'load_spaces') {
                var newState = this.state;
                newState.board.spaces = data.data;
                this.setState(newState);
            }
            /*else if (data.action === 'load_space') {
                var newState = this.state;
                newState.board.spaces = data.data;
                this.setState(newState);
            }*/
        };
    }

    loadSpaces() {
        client.send(JSON.stringify({
            action: 'load_spaces',
        }));
    }

    saveSpaces() {
        client.send(JSON.stringify({
            action: 'save_spaces',
            data: this.state.board.spaces
        }));
    }

    saveSpace(space) {
        client.send(JSON.stringify({
            action: 'save_space',
            data: space
        }));
    }

    render() {
        return (
            <div>
                <Board
                    state={this.state}
                    saveSpace={this.saveSpace}
                />
            </div>
        )
    }
}
